/**
 * Copyright 2024 Sophos Limited. All rights reserved.
 *
 * 'Sophos' and 'Sophos Anti-Virus' are registered trademarks of Sophos Limited and Sophos Group. All other product
 * and company names mentioned are trademarks or registered trademarks of their respective owners.
 */
import logo from "../../resources/socos_logo.svg";
import { useEffect, type ReactElement, useState } from "react";
import { Outlet } from "react-router-dom";
import {
  Box,
  Button,
  Collapse,
  Divider,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import { ListItemLink } from "../../components/ListItemLink/ListItemLink";
import { ResizableSplitPanel } from "../../components/ResizableSplitPanel/ResizableSplitPanel";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { accessTokenAtom } from "../../services/userService";
import { useSetAtom } from "jotai";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

/**
 * The apps main layout component for routing
 * @returns The layout component
 */
export function Layout(): ReactElement {
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const [filterStatsNavExpanded, setFilterStatsNavExpanded] = useState(false);
  const [threatMappingsNavExpanded, setThreatMappingsNavExpanded] = useState(false);

  const setAccessToken = useSetAtom(accessTokenAtom);

  /** Store user Cognito AccessToken in atom */
  useEffect(() => {
    let accessToken = "";
    user.getSession((error: Error | null, session: CognitoUserSession) => {
      if (error !== null) {
        throw new Error(error.message);
      }

      accessToken = session.getAccessToken().getJwtToken();
    });

    setAccessToken(accessToken);
  }, [user, setAccessToken]);

  const signOutHandler = () => signOut();

  return (
    <Box width="100vw" height="100vh">
      <ResizableSplitPanel
        cacheInLocalStorageKey="main-layout-split"
        initialSplitValue={200}
        absoluteSize="first"
        orientation="horizontal"
        first={
          <Paper sx={{ py: 2, mr: 0.5, height: "100%" }} square elevation={4}>
            <Grid height="100%" container direction={"column"}>
              <Grid item xs={1}>
                <Box display="flex" justifyContent="center">
                  <img src={logo} className="App-logo" alt="logo" />
                </Box>
              </Grid>

              <Grid item xs={8}>
                <List component="nav">
                  <ListItemLink to="/" primary="Home" />
                  <ListItemLink to="/reprocessing" primary="Reprocess Business Errors" />
                  <ListItemLink to="/query" primary="Query Business Errors" />
                  <ListItemLink to="/deadletterqueue" primary="Queue Errors" />
                  <ListItemLink to="/ingestalert" primary="Ingest Alert" />
                  <ListItemButton
                    selected={threatMappingsNavExpanded}
                    onClick={() => setThreatMappingsNavExpanded(!threatMappingsNavExpanded)}
                  >
                    <ListItemText primary="Threat Mappings" />
                    {threatMappingsNavExpanded ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={threatMappingsNavExpanded} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItemLink to="/threatmappings/failedthreatmappings" primary="Failed threat mappings" />
                      <ListItemLink to="/threatmappings/predictedthreatmappings" primary="Predicted threat mappings" />
                    </List>
                  </Collapse>
                  <ListItemButton
                    selected={filterStatsNavExpanded}
                    onClick={() => setFilterStatsNavExpanded(!filterStatsNavExpanded)}
                  >
                    <ListItemText primary="Filter Statistics" />
                    {filterStatsNavExpanded ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={filterStatsNavExpanded} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItemLink to="/filterstatistics/hitrules" primary="Find rules hit by org" />
                      <ListItemLink to="/filterstatistics/ruleorganisations" primary="Find orgs that hit rule" />
                      <ListItemLink to="/filterstatistics/mostcommonrules" primary="Find most common rules" />
                    </List>
                  </Collapse>
                </List>
              </Grid>

              <Grid item xs={3} display="flex" flexDirection={"column"} justifyContent={"flex-end"}>
                <Box padding={1.5} paddingBottom={0.5}>
                  <Typography>{`Current User: ${user?.username || ""}`}</Typography>
                  <Box paddingX={0} paddingY={1}>
                    <Divider />
                  </Box>
                  <Box display={"flex"} justifyContent={"flex-start"}>
                    <Button data-testid="sign-out-button" onClick={signOutHandler} variant="contained">
                      Sign Out
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        }
        second={
          <Box sx={{ p: 2, overflow: "auto", height: "100%" }}>
            {/* The <Outlet> renders whatever the child route */}
            <Outlet />
          </Box>
        }
      />
    </Box>
  );
}
